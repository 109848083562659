import { connect } from 'react-redux';

import { RootState } from 'Util/Store/Store.type';

import Slider from './Slider.component';
import { SliderContainerMapDispatchProps, SliderContainerMapStateProps } from './Slider.type';

/** @namespace PlugAndSell2/Component/Slider/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): SliderContainerMapStateProps => ({
    device: state.ConfigReducer.device,
});

/** @namespace PlugAndSell2/Component/Slider/Container/mapDispatchToProps */
export const mapDispatchToProps = (): SliderContainerMapDispatchProps => ({});

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/always-both-mappings
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Slider);
